import { projectUid } from '@/configs/contents'
import axios from 'axios'

export const MEMEX_URLS = {
  GET_LISTS: {
    MAIN: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/home_main/contents/search/v1`,
    PROJECTS: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/surveylist/contents/search/v1`,
    RECORDS: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/records/contents/search/v1`,
    ARCHIVES: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/archives/contents/search/v1`,
    PEOPLE: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/people/contents/search/v1`,
    RECORD_DETAIL_CONTENTS: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/contents/contents/search/v1`,
    VIDEOS: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/videos/contents/search/v1`,
    IMAGES: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/images/contents/search/v1`,
  },
  GET_CATEGORIES: {
    archives: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/archives/selectors`,
    donate: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/donate/selectors`,
  },
  GET_NOTICES: {
    archives: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/notices_archives/contents/search/v1`,
    activities: `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/notices_activities/contents/search/v1`,
  },
}

export const createMemexFetcher = () => {
  return axios.create({
    headers: {
      'Access-Token': process.env.REACT_APP_MEMEX_APP_KEY ?? '',
    },
  })
}
