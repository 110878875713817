export const projectUid = '7c8d8ea6' // 프로젝트 UID
export const modelArchivesDevKey = 'archives' // 도시기록문화아카이브
export const modelRecordsDevKey = 'records' // 구술채록
export const modelPeopleDevKey = 'people' // 구술_인물
export const modelContentsDevKey = 'contents' // 구술_컨텐츠
export const modelImagesDevKey = 'images' // 이미지 자료
export const modelVideosDevKey = 'videos' // 영상 자료
export const modelHomeMapDevKey = 'home_map' // 홈화면-지도
export const modelHomeArchivesDevKey = 'home_archives' // 홈화면-도시문화기록아카이브
export const modelHomeRecordsDevKey = 'home_records' // 홈화면-구술
export const modelHomeMainDevKey = 'home_main' // 메인 홈화면
export const modelSurveyListDevKey = 'surveylist' // 과제목록 홈화면

export const modelNoticesArchivesDevKey = 'notices_archives' // 소식_대덕문화원 아카이브 사업
export const modelNoticesActivitiesDevKey = 'notices_activities' // 소식_대덕마을기록단 활동

export const DEFAULT_PAGE_SIZE = 15
export const DATA_CHUNK_SIZE = 100

export const DEFAULT_MAP_COORDINATES = [127.4356, 36.4267]
export const DEFAULT_MAP_RECORDS_COORDINATES = [
  127.4421707189723, 36.41485653217029,
]

export const ARCHIVE_MAP_ZOOM = [
  {
    width: 501,
    zoom: 11.5,
  },
  {
    width: 768,
    zoom: 12,
  },
  {
    width: 1024,
    zoom: 11.5,
  },
  {
    width: 1600,
    zoom: 11.7,
  },
  {
    width: 1920,
    zoom: 12,
  },
  {
    width: 2560,
    zoom: 12.5,
  },
]

export const RECORD_MAP_ZOOM = [
  {
    width: 501,
    zoom: 11.1,
  },
  {
    width: 768,
    zoom: 11.3,
  },
  {
    width: 1024,
    zoom: 11.4,
  },
  {
    width: 1600,
    zoom: 11.5,
  },
  {
    width: 1920,
    zoom: 11.5,
  },
  {
    width: 2560,
    zoom: 12.8,
  },
]

export const archivesFilters = [
  {
    label: '사건',
    children: ['도시', '사회', '생활', '의례', '축제'],
  },
  {
    label: '문헌',
    children: ['역사', '문학', '생활'],
  },
  {
    label: '장소',
    children: [
      '거주지',
      '공공시설',
      '관공서',
      '교육',
      '교통',
      '상업시설',
      '종교시설',
      '문화시설',
      '문화예술',
      '유적',
      '인문지리',
      '자연물',
      '자연지리',
    ],
  },
  {
    label: '유물',
    children: ['유물'],
  },
  {
    label: '인물',
    children: ['개인', '단체', '구술'],
  },
]

export const archivesFiltersMobile = [
  [
    {
      label: '사건',
      children: ['도시', '사회', '생활', '의례', '축제'],
    },
    {
      label: '문헌',
      children: ['역사', '문학', '생활'],
    },
    {
      label: '인물',
      children: ['개인', '단체', '구술'],
    },
  ],
  [
    {
      label: '유물',
      children: ['유물'],
    },
    {
      label: '장소',
      children: [
        '거주지',
        '공공시설',
        '관공서',
        '교육',
        '교통',
        '상업시설',
        '종교시설',
        '문화시설',
        '문화예술',
        '유적',
        '인문지리',
        '자연물',
        '자연지리',
      ],
    },
  ],
]

export const donatePrivacyTerms = `개인정보를 제공받는 자
대덕문화원

개인정보의 수집·이용 목적
기증 신청자의 본인확인, 개인 식별, 부정이용방지, 불만 처리 등 원활한 사업 운영을 위함입니다.

이용 또는 제공하는 개인정보의 항목
기증자 성명, 연락처, 이메일, 주소 등

개인정보의 보유 및 이용 기간
위 개인정보는 수집·이용에 관한 동의일로부터 위 이용목적을 위하여 보유·이용됩니다. 수집된 개인정보는 보유 및 이용 기간이 1년을 넘지 않습니다.

개인정보 수집 동의 거부의 권리
신청자는 개인정보 수집 동의를 거부할 권리가 있습니다. 단, 동의가 없을 경우 관련 서비스 이용이 어려울 수 있습니다. 위 내용을 확인하고 동의합니다.`
