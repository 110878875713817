import clsx from 'clsx'
import { ReactNode } from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'

import useIsMobile from '@/hooks/useIsMobile'
import { ConvertedRecordItem } from '@/types/record'

import RecordsInfo from '@/components/records/RecordsInfo'
import RecordsInfoInterviewee from '@/components/records/RecordsInfoInterviewee'
import RecordsInfoInterviewer from '@/components/records/RecordsInfoInterviewer'
import RecordsInfoSummary from '@/components/records/RecordsInfoSummary'

import { ReactComponent as CaretBottom } from '@/assets/images/ico-tip-bottom.svg'
import { ReactComponent as CaretTop } from '@/assets/images/ico-tip-top.svg'

export const isDetailOpenedState = atom({
  key: 'isDetailOpenedState',
  default: false,
})

type TabNames = 'summary' | 'interviewee' | 'interviewer'

const selectedTabState = atom<TabNames>({
  key: 'selectedTabState',
  default: 'summary',
})

const RecordsInfoDetails = ({ item }: { item: ConvertedRecordItem }) => {
  const isMobile = useIsMobile()
  const [isDetailOpened, setIsDetailOpened] =
    useRecoilState(isDetailOpenedState)
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState)

  const handleLabelClick = (tabName: TabNames) => () => {
    setSelectedTab(tabName)

    if (isMobile) {
      setIsDetailOpened(true)
    } else {
      setIsDetailOpened((prev) => !prev)
    }
  }

  return (
    <RecordsInfoDetails.Container>
      <RecordsInfoDetails.Header>
        <RecordsInfo.Label
          className={clsx(
            isMobile &&
              isDetailOpened &&
              selectedTab === 'summary' &&
              'underline text-black'
          )}
          onClick={handleLabelClick('summary')}>
          {isMobile ? '조사 개요' : '구술 조사 개요'}
        </RecordsInfo.Label>

        <RecordsInfo.Label
          className={clsx(
            isMobile &&
              isDetailOpened &&
              selectedTab === 'interviewee' &&
              'underline text-black'
          )}
          onClick={handleLabelClick('interviewee')}>
          구술자 정보
        </RecordsInfo.Label>

        <RecordsInfo.Label
          className={clsx(
            isMobile &&
              isDetailOpened &&
              selectedTab === 'interviewer' &&
              'underline text-black'
          )}
          onClick={handleLabelClick('interviewer')}>
          면담자 정보
        </RecordsInfo.Label>

        {isMobile ? (
          isDetailOpened ? (
            <CaretTop
              className="ml-auto"
              onClick={() => setIsDetailOpened((prev) => !prev)}
            />
          ) : (
            <CaretBottom
              className="ml-auto"
              onClick={() => setIsDetailOpened((prev) => !prev)}
            />
          )
        ) : (
          <></>
        )}
      </RecordsInfoDetails.Header>

      {!isMobile && (
        <RecordsInfoDetails.Contents>
          <RecordsInfoDetails.ContentsColumn>
            <RecordsInfoSummary item={item} key={''} />
          </RecordsInfoDetails.ContentsColumn>

          <RecordsInfoDetails.ContentsColumn>
            <RecordsInfoInterviewee item={item} key={''} />
          </RecordsInfoDetails.ContentsColumn>

          <RecordsInfoDetails.ContentsColumn>
            <RecordsInfoInterviewer item={item} key={''} />
          </RecordsInfoDetails.ContentsColumn>
        </RecordsInfoDetails.Contents>
      )}

      {isMobile && (
        <RecordsInfoDetails.Contents>
          {selectedTab === 'summary' && (
            <RecordsInfoDetails.ContentsColumn>
              <RecordsInfoSummary item={item} key={''} />
            </RecordsInfoDetails.ContentsColumn>
          )}

          {selectedTab === 'interviewee' && (
            <RecordsInfoDetails.ContentsColumn>
              <RecordsInfoInterviewee item={item} key={''} />
            </RecordsInfoDetails.ContentsColumn>
          )}

          {selectedTab === 'interviewer' && (
            <RecordsInfoDetails.ContentsColumn>
              <RecordsInfoInterviewer item={item} key={''} />
            </RecordsInfoDetails.ContentsColumn>
          )}
        </RecordsInfoDetails.Contents>
      )}
    </RecordsInfoDetails.Container>
  )
}

RecordsInfoDetails.Container = ({ children }: { children: ReactNode }) => {
  const isDetailOpened = useRecoilValue(isDetailOpenedState)

  return (
    <div
      className={clsx(
        'absolute bottom-0 bg-light-gray w-full',
        'h-[55vh] maxmd:h-[calc(100svh-47px)]',
        'duration-300',
        !isDetailOpened && 'translate-y-[calc(100%-50px)]'
      )}>
      {children}
    </div>
  )
}

RecordsInfoDetails.Header = ({ children }: { children: ReactNode }) => {
  return (
    <ul className="flex items-center px-[10px] md:px-0 h-[50px]">{children}</ul>
  )
}

RecordsInfoDetails.Contents = ({ children }: { children: ReactNode }) => {
  return <div className="flex h-full">{children}</div>
}

RecordsInfoDetails.ContentsColumn = ({ children }: { children: ReactNode }) => {
  return (
    <div className="h-[calc(55vh-50px)] maxmd:h-full overflow-y-scroll flex-1 border-r-[1px] border-primary [&:last-child]:border-r-0">
      {children}
    </div>
  )
}

export default RecordsInfoDetails
