import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { atom, useRecoilState } from 'recoil'

import Layout from '@/components/Layout'
import Loading from '@/components/Loading'
import Checkbox from '@/components/common/Checkbox'
import { donatePrivacyTerms } from '@/configs/contents'
import { DONATION_FIELD_NAMES } from '@/constants/donation'
import useModelCategory from '@/hooks/useModelCategory'
import { setArchiveFilter } from '@/states/actions/contents.actions'
import { getAllFilters } from '@/states/reducers/contents.reducer'
import { useAppDispatch } from '@/states/store'
import { 기증서_필수값_점검 } from '@/utils'
import { createDonation } from '@/utils/file'

export interface DonationInput {
  name: string
  email: string
  phone: string
  address: string
  archiveName: string
  archiveType: number | null
  origin: string
  productionDate: string
  collector: string
  collectedAt: string
  description: string
  attachedFile: File | null
  agreement: boolean
}

const donationInputState = atom<DonationInput>({
  key: 'donationInputState',
  default: {
    name: '',
    email: '',
    phone: '',
    address: '',
    archiveName: '',
    archiveType: null,
    origin: '',
    productionDate: '',
    collector: '',
    collectedAt: '',
    description: '',
    attachedFile: null,
    agreement: false,
  },
})

const Donates = () => {
  const dispatch = useAppDispatch()

  const [isCompleted, setIsCompleted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(setArchiveFilter(getAllFilters(), ''))
  }, [])

  const [donationInput, setDonationInput] = useRecoilState(donationInputState)

  const { data: dataFormatCategories, isLoading: isLoadingCategories } =
    useModelCategory('donate', 'data_format')

  const handleChangeInput = (inputName: keyof DonationInput) => (e) => {
    setDonationInput((prev) => ({
      ...prev,
      [inputName]: e.target.value,
    }))
  }

  const handleChangeFile = async (e) => {
    const file = e.target.files[0]

    if (!file) return

    const isJpeg = file.type === 'image/jpeg'
    const isPng = file.type === 'image/png'
    const isPdf = file.type === 'application/pdf'
    const isHwp = file.type === '' && file.name.includes('.hwp')

    const isValidFileFormat = isJpeg || isPng || isPdf || isHwp

    if (!isValidFileFormat) {
      alert('올바른 파일 형식이 아닙니다.')
      e.target.value = null

      return
    }

    const FILE_SIZE_LIMIT_MEGABYTE = 20
    const fileSizeInMegabyte = Number((file.size / (1024 * 1024)).toFixed(2))
    const isOversized = fileSizeInMegabyte > FILE_SIZE_LIMIT_MEGABYTE

    if (isOversized) {
      alert('20MB 이하의 파일만 첨부할 수 있습니다.')

      return
    }

    // 먼저 file을 상태로 저장해두고, submit 할 때 서버로 POST 요청한다
    setDonationInput((prev) => ({
      ...prev,
      attachedFile: file,
    }))
  }

  const handleChangeAgreement = (e) => {
    setDonationInput((prev) => ({
      ...prev,
      ['agreement']: !prev['agreement'],
    }))
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    const {
      name,
      email,
      phone,
      address,
      archiveName,
      archiveType,
      origin,
      productionDate,
      collector,
      collectedAt,
      description,
      attachedFile,
      agreement,
    } = donationInput

    const { isValid, invalidField } = 기증서_필수값_점검(donationInput)

    if (!isValid && invalidField) {
      alert(
        `${
          DONATION_FIELD_NAMES[invalidField as unknown as string]
        }을(를) 확인해주세요.`
      )

      setIsLoading(false)

      return
    }

    await createDonation({
      publish: true,
      data: {
        donor: {
          KO: name,
        },
        email,
        contact: phone,
        address,
        data_title: archiveName,
        data_format: [Number(archiveType)],
        producer: origin,
        production_date: productionDate,
        collector,
        collect_date: collectedAt,
        details: description,
        agreement: true,
        attachment: attachedFile,
      },
    })

    setIsLoading(false)
    setIsCompleted(true)
  }

  const {
    name,
    email,
    phone,
    address,
    archiveName,
    archiveType,
    origin,
    productionDate,
    collector,
    collectedAt,
    description,
    agreement,
  } = donationInput

  useEffect(() => {
    if (archiveType !== null) return
    if (!dataFormatCategories || !dataFormatCategories[0]) return

    const setArchiveTypeDefaultValue = () =>
      setDonationInput((prev) => ({
        ...prev,
        ['archiveType']: dataFormatCategories[0].categoryId,
      }))

    setArchiveTypeDefaultValue()
  }, [archiveType, dataFormatCategories])

  if (isLoadingCategories || !dataFormatCategories) return <Loading />

  return (
    <Layout full overflow={isCompleted ? 'hidden' : 'auto'}>
      <div className="px-5 py-3 bordered-b">
        <h1 className="para-lg color-primary">기증</h1>
      </div>

      <div className="py-10 container mx-auto max-w-[820px] px-5 lg:px-0">
        <h2 className="mb-10 text-2xl font-bold color-primary">기증 안내</h2>

        <p className="mb-5 text-lg leading-7 md:mb-0">
          대덕문화원에서는 여러분들이 소장하고 계신 대덕구와 관련된 문서, 사진,
          음성, 영상 등을 기증받고 있습니다. 기증해주신 소중한 자료는 대덕문화원
          관리자 평가를 통해 시스템에 등록되며, 본 홈페이지를 통해 공개됩니다.
          <br />
          <br />
          기증해주신 자료는 본 홈페이지 및 대덕 도시역사문화 아카이브 사업과
          관련하여 이용, 복제, 전시, 공연, 배포, 대여 2차적 저작물 제작 등
          다양한 형태로 활용될 수 있도록 대덕문화원에 비독점적인 저작재산권을
          허여하게 됨을 알려드립니다.
        </p>
      </div>

      <div className="pt-10 pb-48 container mx-auto max-w-[610px] px-5 lg:px-0">
        <div className="space-y-5 text-sm">
          <div>
            <div className="font-bold color-primary">기증자 이름*</div>

            <div className="text-[#6C6C6C]">
              이름 또는 단체명을 입력해주세요
            </div>

            <input
              type="text"
              maxLength={50}
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('name')}
              value={name}
            />
          </div>

          <div>
            <div className="font-bold color-primary">이메일*</div>

            <input
              type="email"
              maxLength={100}
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('email')}
              value={email}
            />
          </div>

          <div>
            <div className="font-bold color-primary">연락처*</div>
            <input
              type="text"
              maxLength={30}
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('phone')}
              value={phone}
            />
          </div>

          <div>
            <div className="font-bold color-primary">주소*</div>

            <div className="text-[#6C6C6C]">
              거주하는 곳 또는 단체가 거주하는 주소를 입력해주세요
            </div>
            <input
              type="text"
              maxLength={100}
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('address')}
              value={address}
            />
          </div>

          <div>
            <div className="font-bold color-primary">자료 제목*</div>

            <input
              type="text"
              maxLength={200}
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('archiveName')}
              value={archiveName}
            />
          </div>

          <div>
            <div className="font-bold color-primary">자료 형태*</div>

            <select
              className="block w-full p-2 mt-2 bg-light-gray border-r-[16px] border-transparent"
              onChange={handleChangeInput('archiveType')}
              value={archiveType ?? ''}>
              {dataFormatCategories.map(
                ({ categoryId, name: categoryName }, index) => {
                  return (
                    <option key={`${categoryName}-${index}`} value={categoryId}>
                      {categoryName}
                    </option>
                  )
                }
              )}
            </select>
          </div>

          <div>
            <div className="font-bold color-primary">생산자</div>

            <div className="text-[#6C6C6C]">
              자료를 생산하신 분의 이름 또는 단체명을 입력해주세요
            </div>

            <input
              type="text"
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('origin')}
              value={origin}
            />
          </div>

          <div>
            <div className="font-bold color-primary">생산일자</div>

            <div className="text-[#6C6C6C]">YYYYDDMM (예: 20230101)</div>

            <input
              type="text"
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('productionDate')}
              value={productionDate}
            />
          </div>

          <div>
            <div className="font-bold color-primary">수집자</div>

            <div className="text-[#6C6C6C]">
              자료를 수집하신 분의 이름 또는 단체명을 입력해주세요
            </div>

            <input
              type="text"
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('collector')}
              value={collector}
            />
          </div>

          <div>
            <div className="font-bold color-primary">수집일자</div>
            <div className="text-[#6C6C6C]">YYYYDDMM (예: 20230101)</div>
            <input
              type="text"
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('collectedAt')}
              value={collectedAt}
            />
          </div>

          <div>
            <div className="font-bold color-primary">세부내용</div>
            <textarea
              rows={6}
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeInput('description')}
              value={description}
            />
          </div>

          <div>
            <div className="font-bold color-primary">첨부파일</div>
            <div className="text-[#6C6C6C]">
              20MB 이하의 이미지(jpg, png), 문서(hwp, pdf) 파일
            </div>
            <input
              type="file"
              className="block w-full p-2 mt-2 bg-light-gray"
              onChange={handleChangeFile}
            />
          </div>

          <div>
            <div className="font-bold color-primary">
              개인정보 수집 및 이용 동의서*
            </div>

            <textarea
              rows={6}
              className="block w-full p-2 mt-2 resize-none bg-light-gray"
              onChange={() => {}}
              value={donatePrivacyTerms.replace(/\\n/g, '<br/>')}></textarea>

            <div
              className="flex items-center pt-3 cursor-pointer"
              onClick={handleChangeAgreement}>
              <input
                className="mr-[6px]"
                type="checkbox"
                checked={agreement}
                hidden
              />
              <Checkbox className="mr-[4px]" isSelected={agreement} />
              <span className="select-none">동의합니다</span>
            </div>
          </div>
        </div>

        <div className="pt-[60px]">
          <button
            type="button"
            className="btn h-[46px] w-full block bg-primary text-white font-bold [&:disabled]:bg-slate-400 select-none"
            onClick={handleSubmit}
            disabled={isLoading || isCompleted}>
            기증하기
          </button>
        </div>
      </div>

      {isCompleted && <Donates.CompleteDialog />}
    </Layout>
  )
}

Donates.Loading = () => {
  return (
    <div className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-50 color=white text-[18px] font-bold">
      등록중입니다..
    </div>
  )
}

Donates.CompleteDialog = () => {
  const handleButtonClick = () => {
    location.href = '/'
  }

  return (
    <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-50">
      <div
        className={clsx(
          'p-[20px] pb-[28px] leading-[28.8px] text-[18px]',
          'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
          'bg-primary color-white',
          'flex flex-col justify-between items-center',
          'w-[520px]'
        )}>
        소중한 자료를 기증해주셔서 감사합니다. <br />
        기증해주신 자료는 관리자 평가를 통하여 시스템에 등록될 예정이며, 본
        홈페이지를 통해 공개될 수 있습니다.
        <button
          className="bg-white color-black pt-[11px] pb-[13px] px-[12px] text-[16px] leading-[22.4px] text-center w-[156px] mt-[60px]"
          onClick={handleButtonClick}>
          확인
        </button>
      </div>
    </div>
  )
}

export default Donates
