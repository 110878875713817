import { useQuery } from 'react-query'

import { ConvertedImageItem, ImageItem } from '@/types/images'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'
import { pipe } from '@fxts/core'

const useImages = (imageNames?: Array<string>) => {
  return useQuery({
    queryKey: ['images'],
    queryFn: async () => {
      const memexFetcher = createMemexFetcher()

      return await memexFetcher.post(MEMEX_URLS.GET_LISTS.IMAGES, {
        size: 999,
        page: 0,
      })
    },
    select(data) {
      const converted = pipe(
        data,
        (data) => data.data.list,
        (list) =>
          list.map(({ uid, data }) => {
            return {
              uid,
              ...data,
            }
          }),
        (list) => list.map((item) => convertImageItem(item))
      )
      if (imageNames) {
        return converted.filter((item) => {
          const hasImage = Boolean(
            imageNames.find((name) => name === item.search_name)
          )

          return hasImage
        }) as Array<ConvertedImageItem>
      }

      return converted as Array<ConvertedImageItem>
    },
    refetchOnWindowFocus: false,
  })
}

const convertImageItem = (imageItem: ImageItem) => {
  return Object.entries(imageItem).reduce((acc, [key, value]) => {
    acc[key] = value

    if (value['KO'] !== undefined) {
      acc[key] = value['KO']
    }

    if (key === 'files') {
      acc[key] = value.map(({ file: { name, path, thumbnailList } }) => ({
        name,
        path,
        thumbnailList: (thumbnailList ?? []).map(({ path }) => {
          return path
        }),
      }))

      return acc
    }

    if (value instanceof Array && value[0]?.languageMap) {
      acc[key] = value[0].languageMap.KO
    }

    return acc
  }, {})
}

export default useImages
