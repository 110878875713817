import {
  archiveSearchKeywordState,
  selectedFiltersState,
} from '@/components/archives/ArchivesNav'
import useArchives from '@/hooks/useArchives'
import useImages from '@/hooks/useImages'
import useRecordList from '@/hooks/useRecordList'
import { ConvertedArchiveItem } from '@/types/archives'
import { assembleArchiveFilter } from '@/utils'
import { pickImageSource } from '@/utils/convert'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import Popup from 'mapbox-gl/src/ui/popup'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import ArchivesInfo from './ArchivesInfo'

mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

let map
const zoomLevelChange = 16
// const infoLatOffset = 0.004
const infoLatOffset = 0.004
const infoDelay = 300

export interface MapProps {
  zoom: number
  centerCoors: number[]
  scroll: boolean
  move: boolean
}
let markers: Popup[] = []
let dongTextMakers: Popup[] = []

const ArchivesMap = ({ zoom, centerCoors, scroll, move }: MapProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // State
  const [init, setInit] = useState<boolean>(false)
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [archiveInfo, setArchiveInfo] = useState<ConvertedArchiveItem | null>(
    null
  )
  const [currentDong, setCurrentDong] = useState<string | null>(null)
  const selectedFilters = useRecoilValue(selectedFiltersState)
  const searchKeyword = useRecoilValue(archiveSearchKeywordState)

  const { data: archives } = useArchives({
    filters: assembleArchiveFilter(selectedFilters),
    searchKeyword,
  })
  const { data: recordList } = useRecordList({})
  const { data: images } = useImages(
    (archives ?? []).flatMap((archive) => archive.images)
  )

  useEffect(() => {
    if (!archives) return

    if (map && !searchParams.get('uid')) {
      toggleMarkerStyle(map.getZoom())
      setShowInfo(false)

      setTimeout(() => {
        setArchiveInfo(null)
      }, infoDelay)
    } else if (map) {
      const archiveInfo = archives.find(
        (a) => a.uid === searchParams.get('uid')
      )

      if (archiveInfo) {
        setArchiveInfo(archiveInfo)

        map.flyTo({
          center: [
            Number(archiveInfo.lng),
            Number(archiveInfo.lat) - infoLatOffset,
          ],
          zoom: 14,
        })

        setTimeout(() => {
          setShowInfo(true)
        }, infoDelay)
      }
    }
  }, [map, searchParams.get('uid'), archives])

  useEffect(() => {
    toggleMarkersByDong()
  }, [currentDong])

  /**
   * 지도 생성
   */
  const initMap = () => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

    map = new mapboxgl.Map({
      container: 'map', // container ID
      style: process.env.REACT_APP_MAPBOX_STYLE_RECORDS,
      center: centerCoors, // starting position [lng, lat]
      zoom: zoom, // starting zoom
      minZoom: zoom,
    })

    if (!scroll) {
      map.scrollZoom.disable()
    }

    if (!move) {
      map.dragPan.disable()
    }

    map.dragRotate.disable()

    map.addControl(
      new MapboxLanguage({
        defaultLanguage: 'ko',
      })
    )

    map.on('load', async () => {
      // 레이어 추가를 위한 소스 불러오기
      const dongRes = await fetch('/data/daedeok.geojson')
      const dongSource = await dongRes.json()

      const dongPolygons = dongSource.features.map((feature) =>
        turf.multiPolygon(feature.geometry.coordinates)
      )
      const combinedPolygon = dongPolygons.reduce((accPolygon, polygon) => {
        return turf.union(accPolygon, polygon)
      })

      // 대덕구 아웃라인 마스킹
      map.addSource(`outline-masking`, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: turf.mask(combinedPolygon).geometry.coordinates,
          },
        },
      })

      map.addLayer({
        id: 'outline-masking-fill',
        type: 'fill',
        source: 'outline-masking',
        layout: {},
        paint: {
          'fill-color': '#000000',
          'fill-opacity': 0.5,
        },
      })

      // 동별 이름 심볼 추가
      dongSource.features.forEach((feature) => {
        const center = turf.centroid(feature.geometry)
        const markerHtml = document.createElement('div')
        markerHtml.classList.add('map-dong-text')
        markerHtml.innerHTML = feature.properties.name

        const marker = new mapboxgl.Popup({
          className: `map-dong-text-popup`,
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat(center.geometry.coordinates)
          .setDOMContent(markerHtml)
          .addTo(map)

        let cords = center.geometry.coordinates

        marker.getElement().addEventListener('click', () => {
          setCurrentDong(feature.properties.name)

          setTimeout(() => {
            map.flyTo({
              center: cords,
              zoom: 13,
            })
          }, 100)
        })

        dongTextMakers.push(marker)
      })

      // 대덕구 내 동 구분 레이어
      map.addSource('daedeok-area', {
        type: 'geojson',
        data: dongSource,
      })

      map.addLayer({
        id: 'daedeok-area-line',
        type: 'line',
        source: 'daedeok-area',
        layout: {},
        paint: {
          'line-color': '#3b6161',
          'line-width': 2,
        },
      })

      // 클릭을 위한 동 구분 area-fill
      map.addLayer({
        id: 'daedeok-area-fill',
        type: 'fill',
        source: 'daedeok-area',
        paint: {
          'fill-color': 'transparent',
        },
      })

      map.on('click', 'daedeok-area-fill', (e) => {
        const pointer = new mapboxgl.Point(e.point.x, e.point.y)
        const features = map.queryRenderedFeatures(pointer, {
          layers: [`daedeok-area-fill`],
        })
        const clickedArea = features[0]
        const clickedAreaPolygon = turf.multiPolygon([
          clickedArea.geometry.coordinates,
        ])
        const center = turf.centroid(clickedAreaPolygon)

        setCurrentDong(clickedArea.properties.name)

        setTimeout(() => {
          map.flyTo({
            center: center.geometry.coordinates,
            zoom: 13,
          })
        }, 100)
      })

      //
      const layers = map.getStyle().layers

      let firstSymbolId

      for (const layer of layers) {
        if (layer === 'symbol') {
          firstSymbolId = layer.id
          break
        }
      }

      setInit(true)

      // addArchiveMarkers()
    })

    map.on('moveend', () => {
      toggleMarkerStyle(map.getZoom())
    })
  }

  /**
   * 마커 추가
   */
  const addArchiveMarkers = (targetDong) => {
    removeMarkers()

    const addedMarkers: Popup[] = []

    archives
      .filter((item) => item.location_category === targetDong)
      .forEach((item) => {
        const imageSources = item.images.map((imageName) => {
          return images.find((image) => image.search_name === imageName)
        })
        const imageSource = pickImageSource(imageSources[0])

        // 마커 엘리먼트 생성
        const markerHtml = document.createElement('div')
        markerHtml.classList.add('map-dot-content-container')
        markerHtml.classList.add('map-dot-content-info')
        markerHtml.classList.add('hidden-map')
        markerHtml.setAttribute('data-uid', item.uid)
        markerHtml.setAttribute('title', item.title)
        markerHtml.innerHTML = `
        ${
          item.images && item.images[0]
            ? `<div class='image' style='background-image: url(${imageSource})'></div>`
            : ''
        }
        <div class='flex justify-center'><div class='name'>${
          item.title
        }</div></div>
      `

        const isRecordItem =
          item.category_main === '인물' && item.category_sub === '구술'

        markerHtml.addEventListener('click', function () {
          // 레코드(대덕구 사람들 = 구술)
          if (isRecordItem) {
            const recordInfo = recordList.find(
              (recordItem) => recordItem.interviewee === item.address
            )

            if (recordInfo) {
              navigate(`/records?uid=${recordInfo.uid}`)
            }

            return
          }

          // 아카이브
          navigate('/archives/map?uid=' + item.uid)
        })
        markerHtml.addEventListener('mouseenter', function () {
          resetOtherMarkers(map.getZoom(), item.uid)
        })
        markerHtml.addEventListener('mouseleave', function () {
          toggleMarkerStyle(map.getZoom())
        })

        try {
          if (item.lat && item.lng && item.lat && item.lng) {
            const marker = new mapboxgl.Popup({
              className: `map-dot-popup cursor-pointer z-0 ${item.location_category}`,
              closeButton: false,
              closeOnClick: false,
            })
              .setLngLat([Number(item.lng), Number(item.lat)])
              .setDOMContent(markerHtml)
              .addTo(map)

            // marker.option.dong = item.location_category

            addedMarkers.push(marker)
          }
        } catch (e) {
          console.log(item)
        }
      })

    markers = addedMarkers
    toggleMarkerStyle(map.getZoom())
  }

  /**
   * 선택 동별 마커 표시
   */
  const toggleMarkersByDong = useCallback(() => {
    if (!currentDong) return

    removeMarkers()
    addArchiveMarkers(currentDong)
  }, [currentDong])

  /**
   * Dot 마커 지우기
   */
  const removeMarkers = () => {
    markers.map((marker) => {
      marker.remove()
    })

    markers = []

    document.querySelectorAll('.map-dot-popup').forEach((popup) => {
      popup.remove()
    })

    // removeInfoMaker()
  }

  /**
   * Zoom 별 마커 스타일 변경
   */
  const toggleMarkerStyle = (zoomLevel) => {
    const popups = document.querySelectorAll('.map-dot-popup')
    const currentArchiveUid = window.location.search
      ? window.location.search.split('?uid=')[1]
      : null

    popups.forEach((popup) => {
      const popupContainer = popup.querySelector('.map-dot-content-container')
      const popupContainerUid = popupContainer?.getAttribute('data-uid')

      if (currentArchiveUid && currentArchiveUid === popupContainerUid) {
        popup.classList.add('map-no-dot')
        popupContainer?.classList.remove('map-no-hover')
        popupContainer?.classList.remove('hidden-map')
      } else {
        if (!currentArchiveUid && zoomLevel > zoomLevelChange) {
          popup.classList.add('map-no-dot')
          popupContainer?.classList.remove('map-no-hover')
          popupContainer?.classList.remove('hidden-map')
        } else {
          popup.classList.remove('map-no-dot')
          popupContainer?.classList.add('hidden-map')

          if (currentArchiveUid) {
            popupContainer?.classList.add('map-no-hover')
          }
        }
      }
    })
  }

  /**
   * 다른 마커 최소화
   * @param zoomLevel
   * @param uid
   */
  const resetOtherMarkers = (zoomLevel, uid) => {
    const popups = document.querySelectorAll('.map-dot-popup')
    const currentArchiveUid = window.location.search
      ? window.location.search.split('?uid=')[1]
      : null

    if (currentArchiveUid) return

    popups.forEach((popup) => {
      const popupContainer = popup.querySelector('.map-dot-content-container')
      const popupContainerUid = popupContainer?.getAttribute('data-uid')

      if (zoomLevel > zoomLevelChange && uid !== popupContainerUid) {
        popup.classList.remove('map-no-dot')
        popupContainer?.classList.add('hidden-map')
      } else if (uid === popupContainerUid) {
        popupContainer?.classList.remove('hidden-map')
      }
    })
  }

  useEffect(() => {
    if (!recordList || !archives || !images) return

    initMap()
  }, [recordList, archives, images])

  if (!recordList || !archives || !images) return <></>

  return (
    <>
      <div
        id="map"
        className="flex-1 w-full h-[85vh] max-h-full mt-[-1px]"></div>

      <ArchivesInfo />
    </>
  )
}

export default ArchivesMap
