import { archivesFilters, DEFAULT_PAGE_SIZE } from '@/configs/contents'
import {
  ContentsState,
  ActionTypesContents,
  ActionsContents,
} from '@/states/interfaces'
import { ArchiveInterface } from '@/types'

export const getAllFilters = () => {
  const filterArr: string[] = []

  archivesFilters
    .map((f) => f.children.map((sf) => f.label + ' ' + sf))
    .forEach((filters) => {
      filters.forEach((filter) => {
        filterArr.push(filter)
      })
    })

  return filterArr
}

export const initialState: ContentsState = {
  loaded: false,
  archives: [],
  filteredArchives: [],
  filters: getAllFilters(),
  keyword: '',
  records: [],
  people: [],
  contents: [],
  images: [],
  videos: [],
  contentsPagination: {
    total: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    current: 1,
    totalPages: 0,
    isLast: false,
  },
  home: {
    map: [],
    archives: [],
    records: [],
  },
  main: null,
  surveylist: [],
}

interface HydratePayload {
  contents: ContentsState
}

const contentsReducer = (
  state = initialState,
  action: ActionsContents
): ContentsState => {
  switch (action.type) {
    // Set Loaded
    case ActionTypesContents.SET_LOADED: {
      return {
        ...state,
        loaded: action.payload.loaded,
      }
    }
    // 도시기록문화아카이브 목록 설정
    case ActionTypesContents.SET_ARCHIVES_LIST: {
      return {
        ...state,
        archives: action.payload.list,
        filteredArchives: action.payload.list,
      }
    }
    // 도시기록문화아카이브 필터 설정
    case ActionTypesContents.SET_FILTER: {
      const filters = action.payload.filters
      const keyword = action.payload.keyword
      let updatedFilteredArchives: ArchiveInterface[] = []

      // 필터별 아카이브 목록
      filters.forEach((filter) => {
        const mainCategory = filter.split(' ')[0]
        const subCategory = filter.split(' ')[1]
        updatedFilteredArchives = [
          ...updatedFilteredArchives,
          ...state.archives.filter(
            (f) =>
              f.data.category_main.KO === mainCategory &&
              f.data.category_sub.KO === subCategory
          ),
        ]
      })

      // 검색어 필터링
      if (keyword) {
        updatedFilteredArchives = updatedFilteredArchives.filter(
          (filter) =>
            filter.data.title?.KO.includes(keyword) ||
            filter.data.desc?.KO.includes(keyword) ||
            filter.data.category_main?.KO.includes(keyword) ||
            filter.data.category_sub?.KO.includes(keyword) ||
            filter.data.address?.KO.includes(keyword) ||
            filter.data.origin?.KO.includes(keyword) ||
            filter.data.author?.KO.includes(keyword)
        )
      }

      return {
        ...state,
        filters,
        keyword,
        filteredArchives: updatedFilteredArchives,
      }
    }
    // 구술채록 목록 설정
    case ActionTypesContents.SET_RECORDS_LIST: {
      const records = action.payload.list

      // 정렬
      // records.sort((a, b) =>
      //   a.data.seq > b.data.seq ? 1 : b.data.seq > a.data.seq ? -1 : 0
      // )

      return {
        ...state,
        records,
      }
    }
    // 구술_인물 목록 설정
    case ActionTypesContents.SET_PEOPLE_LIST: {
      const peopleData = action.payload.list
      const updatedRecords = state.records

      // updatedRecords.forEach((record) => {
      //   record.data.interviewerData = peopleData.find(
      //     (person) =>
      //       person.data.name_search.KO ==
      //       record.data.interviewer[0].languageMap['KO']
      //   )
      //   record.data.intervieweeData = peopleData.find(
      //     (person) =>
      //       person.data.name_search.KO ==
      //       record.data.interviewee[0].languageMap['KO']
      //   )
      //   record.data.reviewerData = peopleData.find(
      //     (person) =>
      //       person.data.name_search.KO ==
      //       record.data.reviewer[0].languageMap['KO']
      //   )
      // })

      return {
        ...state,
        records: updatedRecords,
        people: peopleData,
      }
    }
    // 구술_콘텐츠 목록 설정
    case ActionTypesContents.SET_CONTENTS_LIST: {
      const contentsData = action.payload.list
      const updatedRecords = state.records

      // 정렬
      contentsData.sort((a, b) =>
        Number(a.data.seq.KO) > Number(b.data.seq.KO)
          ? 1
          : Number(b.data.seq.KO) > Number(a.data.seq.KO)
          ? -1
          : 0
      )

      // updatedRecords.forEach((record) => {
      //   record.data.contents = contentsData.filter(
      //     (contents) => contents.data.author.KO === record.data.interviewee.KO
      //   )
      // })

      return {
        ...state,
        records: updatedRecords,
        contents: contentsData,
      }
    }
    // 이미지 자료 목록 설정
    case ActionTypesContents.SET_IMAGES_LIST: {
      const imagesData = action.payload.list.filter(
        (i) => i.data.copyright // && i.data.copyright[0].languageMap.KO === '확보'
      )

      const updatedContents = state.contents
      const updatedArchives = state.archives

      // updatedContents.forEach((contents) => {
      //   const imagesFound = imagesData.filter((image) =>
      //     contents.data.images.map((image) => image.uid).includes(image.uid)
      //   )

      //   contents.data.imagesData = imagesFound
      // })

      updatedArchives.forEach((archive) => {
        const imagesFound = imagesData.filter((image) =>
          archive.data.images.map((image) => image.uid).includes(image.uid)
        )

        archive.data.imagesData = imagesFound
      })

      return {
        ...state,
        contents: updatedContents,
        images: imagesData,
      }
    }
    // 영상 자료 목록 설정
    case ActionTypesContents.SET_VIDEOS_LIST: {
      const videosData = action.payload.list
      const updatedContents = state.contents

      // updatedContents.forEach((contents) => {
      //   const videoFound = videosData.find(
      //     ({ data }) => data.searchname.KO === contents.data.file_name.KO
      //   )

      //   contents.data.videoData = videoFound
      // })

      return {
        ...state,
        contents: updatedContents,
        videos: action.payload.list,
      }
    }
    // 홈 데이터 설정
    case ActionTypesContents.SET_HOME: {
      const homeData = action.payload.data
      const shuffledHomeData = homeData.map
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

      const shuffledArchivesData = homeData.archives
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

      const shuffledRecordsData = homeData.records
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)

      return {
        ...state,
        home: {
          // map: shuffledHomeData,
          map: homeData.map,
          archives: shuffledArchivesData,
          records: shuffledRecordsData,
        },
      }
    }
    // 메인 데이터
    case ActionTypesContents.SET_MAIN: {
      const main = action.payload.list ? action.payload.list[0] : null

      if (main) {
        main.data.story_contentData = state.archives.filter((archive) =>
          main.data.story_content
            .map((content) => content.uid)
            .includes(archive.uid)
        )
      }

      return {
        ...state,
        main,
      }
    }
    // 과제 목록
    case ActionTypesContents.SET_SURVEYLIST: {
      return {
        ...state,
        surveylist: action.payload.list,
      }
    }
    default:
      return state
  }
}

export default contentsReducer
