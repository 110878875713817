/* eslint-disable react/display-name */
import clsx from 'clsx'
import { ReactNode, useEffect, useState } from 'react'

import RecordListIcon from '@/assets/images/record-list.svg'
import RecordMapIcon from '@/assets/images/record-map.svg'
import RecordSearchIcon from '@/assets/images/record-search.svg'
import Layout from '@/components/Layout'
import RecordsInfo from '@/components/records/RecordsInfo'
import RecordsList from '@/components/records/RecordsList'
import RecordsMap from '@/components/records/RecordsMap'
import {
  DEFAULT_MAP_RECORDS_COORDINATES,
  RECORD_MAP_ZOOM,
} from '@/configs/contents'
import useIsMobile from '@/hooks/useIsMobile'
import { setArchiveFilter } from '@/states/actions/contents.actions'
import { getAllFilters } from '@/states/reducers/contents.reducer'
import { useAppDispatch } from '@/states/store'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import IconSearchX from '@/assets/images/icon-search-x.svg'
import IconXWhite from '@/assets/images/icon-x-white.svg'

const infoDelay = 300
const initZoom = 12

export const recordViewTypeState = atom<'list' | 'map'>({
  key: 'recordViewTypeState',
  default: 'list',
})

const Records = () => {
  const dispatch = useAppDispatch()
  const isMobile = useIsMobile()

  const viewType = useRecoilValue(recordViewTypeState)
  const [zoom, setZoom] = useState<number>(initZoom)

  useEffect(() => {
    dispatch(setArchiveFilter(getAllFilters(), ''))

    // 해상도별 Zoom 값 설정
    const _w = window.innerWidth
    let targetZoom = initZoom

    RECORD_MAP_ZOOM.forEach((zoomObj) => {
      if (_w >= zoomObj.width) {
        targetZoom = zoomObj.zoom
      }
    })

    setZoom(targetZoom)
  }, [])

  const isRecordMapPage = viewType === 'map'

  return (
    <Layout full overflow={viewType === 'list' ? 'auto' : 'hidden'}>
      <div
        className={clsx(
          !isRecordMapPage && !isMobile && 'sticky top-[72px] left-0 z-[10]'
        )}>
        <Records.Header asModal={isRecordMapPage} />
        <Records.Navigation />
      </div>

      {viewType === 'map' && Boolean(zoom) && (
        <RecordsMap
          centerCoors={DEFAULT_MAP_RECORDS_COORDINATES}
          zoom={zoom}
          scroll={true}
          move={true}
        />
      )}

      {viewType === 'list' && <RecordsList />}

      <RecordsInfo />
    </Layout>
  )
}

const isModalHiddenByUserState = atom({
  key: 'isModalHiddenByUserState',
  default: false,
})

Records.Header = ({ asModal = false }: { asModal?: boolean }) => {
  const [isModalHiddenByUser, setIsModalHiddenByUser] = useRecoilState(
    isModalHiddenByUserState
  )
  const [isVisible, setIsVisible] = useState(true)

  return (
    <div
      className={clsx(
        'relative',
        asModal && '!fixed bottom-0 left-0 z-40 transition-all duration-700',
        asModal && isVisible && 'translate-y-[0%]',
        asModal && !isVisible && '!translate-y-[100%]',
        !asModal && 'border-b-[1px] border-primary',
        asModal && isModalHiddenByUser && 'hidden'
      )}
      onClick={() => {
        if (!asModal) return

        setIsVisible(false)

        setTimeout(() => {
          setIsModalHiddenByUser(true)
        }, 1000)
      }}>
      <div className="grid md:grid-cols-2">
        <div className={clsx('bg-primary color-light-gray', 'p-4 md:p-5')}>
          <p
            className={clsx(
              'mb-0',
              'text-lg font-medium leading-6 flex items-center justify-between'
            )}>
            2022 디지털 생활사 아카이빙
            {asModal && <img src={IconXWhite} />}
          </p>

          <div className="font-serif text-[40px] leading-[48px] mt-[4px] maxmd:text-[20px]">
            대덕과 사람, 그 자취의 기록
          </div>
        </div>

        <div
          className={clsx(
            'p-5',
            'bordered-l maxmd:!border-l-[0px] bg-white color-primary',
            asModal && 'pt-[12px] px-[10px]'
          )}>
          <p
            className={clsx(
              'mb-0 text-lg leading-7',
              asModal && 'text-[16px] leading-[24px]'
            )}>
            본 면담은 문화체육관광부가 지원하고 한국문화원연합회가 추진하며
            대덕문화원이 운영하는 2022 디지털 생활사 아카이빙 사업의 일환으로
            진행하였습니다. 대청댐 건설로 인하여 살던 마을이 수몰되어 이주하게
            된 대덕구민을 대상으로 수몰된 전통 마을 이야기와 삶의 변화를 겪게 된
            이주민의 이야기를 기록하였습니다.
          </p>
        </div>
      </div>
    </div>
  )
}

export const recordsSearchKeywordState = atom({
  key: 'recordsSearchKeywordState',
  default: '',
})

Records.Navigation = () => {
  const isMobile = useIsMobile()

  const [viewType, setViewType] = useRecoilState(recordViewTypeState)
  const setRecordSearchKeyword = useSetRecoilState(recordsSearchKeywordState)

  const [searchKeyword, setSearchKeyword] = useState('')

  const handleSearchButtonClick = () => {
    setRecordSearchKeyword(searchKeyword)
  }

  useEffect(() => {
    return () => {
      setRecordSearchKeyword('')
    }
  })

  useEffect(() => {
    if (searchKeyword.length < 1) {
      setRecordSearchKeyword('')
    }
  }, [searchKeyword])

  return (
    <div
      className={clsx(
        'flex items-center',
        'leading-5 text-center text-sm font-bold color-primary cursor-pointer',
        'bg-white',
        'maxmd:border-b-[1px] border-primary'
      )}>
      <Records.InputContainer>
        <input
          className={clsx(
            'outline-none',
            'w-full px-[20px] pr-[41px] py-[12.5px]',
            'maxmd:h-[48px]',
            'text-[18px] font-normal leading-[25.2px]',
            'placeholder-[rgba(0,0,0,0.3)]'
          )}
          type="text"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          placeholder={
            isMobile ? '자료명을 입력하세요' : '검색하려는 자료명을 입력하세요'
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (searchKeyword.length < 1) return

              handleSearchButtonClick()
            }
          }}
        />

        {searchKeyword.length >= 1 && (
          <img
            className="w-[16px] h-[16px] absolute top-1/2 -translate-y-1/2 right-[20px] cursor-pointer"
            src={IconSearchX}
            onClick={() => {
              setSearchKeyword('')
              setRecordSearchKeyword('')
            }}
          />
        )}
      </Records.InputContainer>

      {isMobile && (
        <>
          <Records.NavigationButton
            wrapperClassName="flex-1"
            className={clsx(!(viewType === 'list') && 'grayscale opacity-50')}
            onClick={() => setViewType('list')}>
            <img src={RecordListIcon} />
            {!isMobile && <Records.ButtonText>목록보기</Records.ButtonText>}
          </Records.NavigationButton>

          <Records.NavigationButton
            wrapperClassName="flex-1 border-l-[0px]"
            className={clsx(!(viewType === 'map') && 'grayscale opacity-50')}
            onClick={() => setViewType('map')}>
            <img src={RecordMapIcon} />
            {!isMobile && <Records.ButtonText>지도보기</Records.ButtonText>}
          </Records.NavigationButton>
        </>
      )}

      <Records.NavigationButton
        wrapperClassName={clsx(
          'color-white bg-[#3b6161]',
          isMobile && 'flex-1'
        )}
        onClick={handleSearchButtonClick}>
        <img src={RecordSearchIcon} />
        {!isMobile && <Records.ButtonText>검색</Records.ButtonText>}
      </Records.NavigationButton>

      {!isMobile && (
        <>
          <Records.NavigationButton
            className={clsx(!(viewType === 'list') && 'grayscale opacity-50')}
            onClick={() => setViewType('list')}>
            <img src={RecordListIcon} />
            <Records.ButtonText>목록보기</Records.ButtonText>
          </Records.NavigationButton>

          <Records.NavigationButton
            wrapperClassName="border-l-[0px]"
            className={clsx(!(viewType === 'map') && 'grayscale opacity-50')}
            onClick={() => setViewType('map')}>
            <img src={RecordMapIcon} />
            <Records.ButtonText>지도보기</Records.ButtonText>
          </Records.NavigationButton>
        </>
      )}
    </div>
  )
}

Records.InputContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className={clsx(
        'flex-1 border-primary border-[1px] relative',
        'maxmd:border-[0px] flex items-center'
      )}>
      {children}
    </div>
  )
}

Records.NavigationButton = ({
  wrapperClassName = '',
  className = '',
  children,
  onClick,
}: {
  wrapperClassName?: string
  className?: string
  children: ReactNode
  onClick: () => void
}) => {
  return (
    <button
      className={clsx(
        'border-[1px] border-primary',
        'flex justify-center items-center',
        'maxmd:h-[48px] maxmd:!max-w-[55px] maxmd:border-t-[0px] maxmd:border-b-[0px]',
        wrapperClassName
      )}
      onClick={onClick}>
      <span
        className={clsx(
          'flex items-center',
          'px-[46.5px] maxmd:px-[0px] pt-[13.5px] pb-[12.5px]',
          'leading-[22.4px] text-[16px] font-bold',
          className
        )}>
        {children}
      </span>
    </button>
  )
}

Records.ButtonText = ({ children }: { children: ReactNode }) => {
  return (
    <span className="pb-[2px] ml-[8px] maxmd:text-[14px] maxmd:font-semibold whitespace-nowrap">
      {children}
    </span>
  )
}

export default Records
