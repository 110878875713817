import clsx from 'clsx'
import { orderBy } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { atom, useRecoilState, useRecoilValue } from 'recoil'

import { ReactComponent as CaretBottomEmpty } from '@/assets/images/caret-bottom.svg'
import { ReactComponent as CaretTopEmpty } from '@/assets/images/caret-top.svg'
import { ReactComponent as CaretBottom } from '@/assets/images/ico-tip-bottom.svg'
import { ReactComponent as CaretTop } from '@/assets/images/ico-tip-top.svg'
import LocationIcon from '@/assets/images/record-item-locate.svg'
import { RecordsImageViewer } from '@/components/records/RecordsImageViewer'
import RecordsInfoDetails, {
  isDetailOpenedState,
} from '@/components/records/RecordsInfoDetails'
import useImages from '@/hooks/useImages'
import useIsMobile from '@/hooks/useIsMobile'
import useRecordInfo from '@/hooks/useRecordInfo'
import useRecordList from '@/hooks/useRecordList'
import useVideos from '@/hooks/useVideos'
import { ConvertedRecordInfo } from '@/types/recordInfo'
import { convertFormattedSecondAsNumber } from '@/utils'

const isContentsListOpenedState = atom({
  key: 'isContentsListOpenedState',
  default: false,
})

function RecordsInfo() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { data: recordList } = useRecordList({
    recordItemUid: searchParams.get('uid'),
  })
  const { data: recordInfo } = useRecordInfo(
    recordList ? recordList[0]?.interviewee ?? '' : ''
  )
  const { data: videos } = useVideos(
    recordInfo ? recordInfo.map(({ video }) => video) : []
  )
  const { data: images } = useImages(
    recordInfo ? recordInfo.flatMap(({ image }) => image) : []
  )

  // State
  const [currentContents, setCurrentContents] =
    useState<ConvertedRecordInfo | null>(null)
  const [viewer, setViewer] = useState<boolean>(false)
  const [active, setActive] = useState<number>(0)

  const [isContentsListOpened, setIsContentsListOpened] = useRecoilState(
    isContentsListOpenedState
  )

  useEffect(() => {
    if (!recordList || !recordInfo) return

    setCurrentContents(recordInfo[0])
  }, [recordList, recordInfo])

  /**
   * 아카이브 정보 닫기
   */
  const closeInfo = () => {
    navigate(location.pathname)
  }

  /**
   * 이미지 뷰어 열기
   * @param idx
   */
  const openImageViewer = (idx) => {
    setActive(idx)
    setViewer(true)
  }

  const isMobile = useIsMobile()

  const [isRecordItemSelected, setIsRecordItemSelected] = useState(false)

  useEffect(() => {
    setIsRecordItemSelected(!!searchParams.get('uid'))
  }, [searchParams.get('uid')])

  if (!recordList || !recordInfo || !videos || !currentContents || !images)
    return <></>

  const recordItem = recordList[0]

  const currentVideo = videos.find(
    (video) => video.search_name === currentContents.video
  )

  const currentImages = images.filter((image) =>
    currentContents.image.includes(image.search_name)
  )

  return (
    <RecordsInfo.Container>
      <div
        className={clsx(
          'records-info-header',
          'maxmd:!h-[47px]',
          'flex items-center',
          'relative',
          'border-b-[1px] border-primary'
        )}>
        <div
          className={clsx(
            'flex-1 flex items-center leading-5 pt-[15px] pb-[16px] whitespace-nowrap'
          )}>
          <div className="label ml-[20px] mr-[10px]">구술</div>

          <div className="text-[20px] font-bold leading-[28px]">
            {recordItem.interviewee}
          </div>
        </div>

        <div className="flex-[2]">
          <div
            className={clsx(
              'ml-[20px] hidden md:flex',
              'text-[14px] leading-[19.6px] font-bold',
              'flex items-center'
            )}>
            <img className="mr-[4px]" src={LocationIcon} />
            {recordItem.location_address}
          </div>

          {/* 인포 닫기 */}
          <button
            type="button"
            className="btn btn-close absolute right-[20px] top-1/2 -translate-y-1/2"
            onClick={() => closeInfo()}></button>
        </div>
      </div>

      <div className="records-info-body">
        <div
          className={clsx(!isMobile && 'grid grid-cols-3', 'records-info-nav')}>
          {/* 컨텐츠 목록 */}
          {!isMobile && (
            <div className="bordered-r h-[100svh]">
              <ul>
                {orderBy(recordInfo, ['seq']).map((content) => (
                  <li
                    key={`${content.uid}-${content.seq}`}
                    className={`px-5 font-noto-sans-mono font-bold text-sm leading-6 py-1 cursor-pointer  ${
                      currentContents?.uid === content.uid
                        ? 'bg-secondary color-primary'
                        : 'color-black'
                    }`}
                    onClick={() => setCurrentContents(content)}>
                    {content.theme}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* 컨텐츠 목록 (모바일) */}
          {isMobile && (
            <div className="relative">
              <div
                className="h-[50px] flex items-center justify-between px-[10px] text-[16px] leading-[22.4px] font-bold color-primary"
                onClick={() => {
                  setIsContentsListOpened((prev) => !prev)
                }}>
                {currentContents.theme}{' '}
                {isContentsListOpened ? (
                  <CaretBottomEmpty />
                ) : (
                  <CaretTopEmpty />
                )}
              </div>

              {isContentsListOpened && (
                <div className="h-[100svh] overflow-y-scroll bg-white w-full absolute top-[50px] left-0 z-40">
                  {orderBy(recordInfo, ['seq']).map((content) => (
                    <li
                      key={`${content.uid}-${content.seq}`}
                      className={`font-noto-sans-mono font-bold text-sm leading-[22.4px] text-[16px] cursor-pointer  ${
                        currentContents?.uid === content.uid
                          ? 'bg-secondary color-primary'
                          : 'color-black'
                      } list-none min-h-[40px] py-[10px] pl-[10px] flex items-center`}
                      onClick={() => {
                        setCurrentContents(content)
                        setIsContentsListOpened(false)
                      }}>
                      {content.theme}
                    </li>
                  ))}
                </div>
              )}
            </div>
          )}

          {!!currentContents && (
            <div className="col-span-2 h-[100svh] pb-[300px] overflow-scroll">
              {/* 비디오 */}
              {!!currentVideo && (
                <div className="records-info-video">
                  <div className="video-container">
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${
                        currentVideo.url.split('https://youtu.be/')[1]
                      }?start=${
                        currentContents.date_start !== '00:00:00'
                          ? convertFormattedSecondAsNumber(
                              currentContents.date_start
                            )
                          : '1'
                      }&autoplay=${isRecordItemSelected ? '1' : '0'}`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              )}

              {/* 설명 */}
              <div className="p-[20px]">
                <h4
                  className={clsx(
                    'mb-[12px]',
                    'text-[14px] leading-[19.6px] font-bold font-noto-sans-mono'
                  )}>
                  {currentContents.theme}
                </h4>

                <p
                  className="font-chulae text-black text-[23px] leading-[32.2px]"
                  dangerouslySetInnerHTML={{
                    __html: currentContents.summary.replace(/\n/g, '<br />'),
                  }}></p>
              </div>

              {/* 이미지 */}
              {currentImages && currentImages?.length >= 1 && (
                <>
                  <div className="w-full pb-20 overflow-x-auto px-7">
                    <ul className="flex maxmd:flex-col">
                      {currentImages.map((image, idx) => {
                        if (!image.files[0]?.path) return <></>

                        return (
                          <li
                            key={image.uid}
                            className="truncate cursor-pointer w-fit mb-[20px] mr-[10px]"
                            onClick={() => openImageViewer(idx)}>
                            <img
                              src={image.files[0].path}
                              alt={image.name}
                              className="records-info-image"
                            />

                            <p className="text-center w-full color-primary pt-2.5 text-sm leading-5 font-noto-sans-mono">
                              {image.name}
                            </p>
                          </li>
                        )
                      })}
                    </ul>
                  </div>

                  <RecordsImageViewer
                    viewer={viewer}
                    active={active}
                    images={currentImages}
                    onSetActive={(newActive) => setActive(newActive)}
                    onSetViewer={(newViewer) => setViewer(newViewer)}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <RecordsInfoDetails item={recordItem} />
    </RecordsInfo.Container>
  )
}

RecordsInfo.Container = ({
  className = '',
  children,
}: {
  className?: string
  children: ReactNode
}) => {
  const [searchParams] = useSearchParams()
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(!!searchParams.get('uid'))
  }, [searchParams.get('uid')])

  return (
    <div
      className={clsx(
        'w-[100vw] h-[calc(100vh-70px)]',
        'maxmd:h-[100dvh] maxmd:border-t-[0px]',
        'duration-700',
        'fixed bottom-0 left-0 z-40',
        show ? 'translate-y-[0]' : 'translate-y-[100%]',
        'bg-white border-t-[1px] border-primary',
        className
      )}>
      {children}
    </div>
  )
}

RecordsInfo.Label = ({
  className = '',
  children,
  onClick = () => {},
}: {
  className?: string
  children: ReactNode
  onClick?: () => void
}) => {
  const isMobile = useIsMobile()
  const isDetailOpened = useRecoilValue(isDetailOpenedState)

  return (
    <li
      className={clsx(
        'md:flex-1 text-[16px] font-bold leading-[22.4px] cursor-pointer flex items-center justify-between',
        'mr-[20px] md:mr-0 md:pr-[20px]',
        'md:h-full',
        'border-r-[1px] maxmd:border-r-0 [&:last-child]:border-r-0 border-primary',
        'border-b-[1px] !border-b-[#d9d9d9]',
        'color-primary',
        className
      )}
      onClick={onClick}>
      <span className="md:px-[20px] pb-[3px]">{children}</span>

      {!isMobile && (isDetailOpened ? <CaretBottom /> : <CaretTop />)}
    </li>
  )
}

export default RecordsInfo
