import { ConvertedRecordInfo, RecordInfo } from '@/types/recordInfo'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'
import { pipe } from '@fxts/core'
import { useQuery } from 'react-query'

const useRecordInfo = (interviewee?: string) => {
  return useQuery({
    queryKey: ['record-info', interviewee],
    queryFn: async () => {
      const memexFetcher = createMemexFetcher()

      return await memexFetcher.post(
        MEMEX_URLS.GET_LISTS.RECORD_DETAIL_CONTENTS,
        {
          size: 1000,
          page: 0,
        }
      )
    },
    select(data) {
      const converted = pipe(
        data,
        ({ data }) => data.list,
        (list) =>
          list.map(({ uid, data }) => ({
            uid,
            ...data,
          })),
        (list) => list.map((item) => convertRecordInfo(item))
      )

      if (interviewee) {
        return converted.filter(
          (item) => item.author === interviewee
        ) as Array<ConvertedRecordInfo>
      }

      return converted as Array<ConvertedRecordInfo>
    },
    refetchOnWindowFocus: false,
  })
}

const convertRecordInfo = (recordInfo: RecordInfo) => {
  return Object.entries(recordInfo).reduce((acc, [key, value]) => {
    acc[key] = value

    if (value['KO'] !== undefined) {
      acc[key] = value['KO']
    }

    if (key === 'author') {
      acc[key] = value[0].languageMap.KO

      return acc
    }

    if (key === 'video') {
      acc[key] = value[0].languageMap.KO

      return acc
    }

    if (key === 'image') {
      acc[key] = value.map(({ languageMap }) => languageMap.KO)

      return acc
    }

    // Category, Relation
    if (value instanceof Array && value[0]?.languageMap) {
      const newValue = value.map((item) => {
        const newItem = {
          value: item.languageMap.KO,
          ...item,
        }

        delete newItem.languageMap

        return newItem
      })

      acc[key] = newValue
    }

    return acc
  }, {})
}

export default useRecordInfo
