import SubHeader from '@/components/main/common/SubHeader'
import useIsMobile from '@/hooks/useIsMobile'
import useProjects from '@/hooks/useProjects'
import { ConvertedProject } from '@/types/project'
import { useNavigate } from 'react-router'

const MainRecords = () => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  const { data: projects } = useProjects()

  const MainSurveyItem = ({
    survey,
    index,
  }: {
    survey: ConvertedProject
    index: number
  }) => {
    const navigate = useNavigate()

    return (
      <div className={`lg:flex ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
        {/* 이미지 */}
        <div className="lg:w-1/2">
          <img
            src={
              survey.image[0] && survey.image[0].path
                ? survey.image[0].path
                : ''
            }
            alt={survey.project_title}
            className="h-[400px] maxmd:h-[200px] w-full object-cover"
          />
        </div>

        {/* 설명 */}
        <div className="h-[400px] flex items-center justify-center px-10 py-6 lg:w-1/2 bg-light-gray">
          <div>
            {/* 헤더 */}
            <h3 className="mb-5 text-3xl font-bold maxmd:text-[20px] maxmd:leading-[28px] line-clamp-3 h-[72px]">
              {survey.project_title}
            </h3>

            {/* 텍스트 */}
            <p className="mb-14 maxmd:text-[14px] maxmd:leading-[21.56px]">
              {survey.project_intro}
            </p>

            <div
              className="flex items-center space-x-1 font-bold cursor-pointer"
              onClick={() => navigate('/records')}>
              <span className="maxmd:text-[16px] maxmd:leading-[22.4px]">
                이야기 보러가기
              </span>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M4 11V1L10 6L4 11Z" fill="#000000" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="px-5 lg:px-[100px]">
      <div className="flex items-center justify-between pb-5 mb-10 border-b border-primary">
        <SubHeader>대덕구 사람들</SubHeader>

        <div
          className="flex items-center space-x-1 font-bold cursor-pointer color-primary"
          onClick={() => {
            navigate('/projects')
          }}>
          {isMobile ? (
            <span>더 보기</span>
          ) : (
            <span>더 많은 이야기 보러가기</span>
          )}
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M4 11V1L10 6L4 11Z" fill="#3B6161" />
          </svg>
        </div>
      </div>

      <div>
        <ul className="space-y-10">
          {projects ? (
            projects.map((survey, index) => (
              <li key={index}>
                <MainSurveyItem survey={survey} index={index} />
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  )
}

export default MainRecords
