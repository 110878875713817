import { ConvertedImageItem } from '@/types/images'

export const pluckKO = (
  obj: Record<string, unknown> & { languageMap: { KO: string } }
) => {
  return obj.languageMap.KO
}

export const removeNumbers = (str: string) => {
  return str.replace(/\d+/g, '')
}

export const pickImageSource = (image: ConvertedImageItem) => {
  if (!image) return ''

  const imageFile = image.files[0]
  if (!imageFile) return ''

  const largeThumbnail = imageFile.thumbnailList.find((thumbnailPath) =>
    thumbnailPath.includes('LARGE')
  )

  if (largeThumbnail) return largeThumbnail

  return imageFile.path
}

export const convertOrigin = (origin: string) => {
  return origin.split('\r\n')
}
