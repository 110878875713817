import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useSetRecoilState } from 'recoil'

import IconSearchX from '@/assets/images/icon-search-x.svg'
import { archiveSearchKeywordState } from '@/components/archives/ArchivesNav'
import useIsMobile from '@/hooks/useIsMobile'

const MainSearch = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const [searchKeyword, setSearchKeyword] = useState('')
  const setArchiveSearchKeyword = useSetRecoilState(archiveSearchKeywordState)

  return (
    <div className="absolute left-0 w-full bottom-20">
      <div className="px-5 lg:px-[200px]">
        <h1 className="mb-10 text-3xl font-bold leading-9 color-white maxmd:text-[20px] maxmd:leading-[28px]">
          대덕 도시역사문화 아카이브에서
          <br />천 년의 역사가 숨쉬는 대덕의 이야기를 만나보세요.
        </h1>
        <div className="flex items-center">
          <div className="relative flex-1">
            <input
              type="text"
              className="h-[50px] leading-[50px] px-5 w-full outline-none"
              placeholder={
                isMobile
                  ? '자료명을 입력하세요'
                  : '검색하려는 자료명을 입력하세요'
              }
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value)
              }}
              onKeyDown={(e) => {
                if (searchKeyword.length === 0) return

                if (e.key === 'Enter') {
                  setArchiveSearchKeyword(searchKeyword)
                  navigate('/archives/list')
                }
              }}
            />

            {searchKeyword.length >= 1 && (
              <img
                className="w-[16px] h-[16px] absolute top-1/2 -translate-y-1/2 right-[20px] cursor-pointer"
                src={IconSearchX}
                onClick={() => {
                  setSearchKeyword('')
                }}
              />
            )}
          </div>

          <button
            className="w-[180px] maxmd:w-[110px] h-[50px] flex-none flex justify-center items-center text-white bg-primary"
            onClick={() => {
              setArchiveSearchKeyword(searchKeyword)
              navigate('/archives/list')
            }}>
            <div className="flex items-center space-x-2">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M10 10.5L14 14.5" stroke="white" strokeWidth="1.5" />
                <circle
                  cx="6.5"
                  cy="7"
                  r="4.5"
                  stroke="white"
                  strokeWidth="1.5"
                />
              </svg>
              <span>검색</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default MainSearch
