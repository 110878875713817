import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

const HeaderNav = ({ nav }: { nav: boolean }) => {
  const navigate = useNavigate()
  const location = useLocation()

  // State
  const [showSubMobile, setShowSubMobile] = useState(false)

  return (
    <ul className="space-y-5 md:flex md:space-x-9 md:space-y-0 md:pr-3">
      <li
        className={`nav-item ${location.pathname === '/about' ? 'active' : ''}`}
        onClick={() => navigate(`/about`)}>
        소개
      </li>
      <li
        className={`nav-item ${
          location.pathname.includes('/archives') &&
          !location.pathname.includes('notices')
            ? 'active'
            : ''
        }`}
        onClick={() => navigate(`/archives/list`)}>
        아카이브
      </li>
      <li
        className={`nav-item ${
          location.pathname.includes('projects') ? 'active' : ''
        }`}
        onClick={() => navigate(`/projects`)}>
        대덕구 사람들
      </li>
      <li
        className={`nav-item ${
          location.pathname.includes('donates') ? 'active' : ''
        }`}
        onClick={() => navigate(`/donates`)}>
        기증
      </li>
      <li
        className={`nav-item ${
          location.pathname.includes('notices') ? 'active' : ''
        }`}>
        <div
          className="flex items-center space-x-2"
          onClick={() => setShowSubMobile(!showSubMobile)}>
          <span>소식</span>
          <svg
            className={`md:hidden ${showSubMobile ? 'rotate-180' : ''}`}
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M11 8L1 8L6 2L11 8Z" fill="#F0F0F0" />
          </svg>
        </div>

        <div
          className={`${
            showSubMobile ? 'show-sub-mobile' : ''
          } nav-sub-list md:absolute top-full md:right-0 w-full md:w-60 md:bg-white md:translate-x-8`}>
          <ul className="w-full md:absolute top-full md:right-0 md:bg-white md:translate-x-8">
            <li
              className={`${
                location.pathname.includes('notices/archives') ? 'active' : ''
              }`}
              onClick={() => navigate(`/notices/archives`)}>
              대덕문화원 아카이브 사업
            </li>
            <li
              className={`${
                location.pathname.includes('notices/activities') ? 'active' : ''
              }`}
              onClick={() => navigate(`/notices/activities`)}>
              대덕마을기록단 활동
            </li>
          </ul>
        </div>
      </li>
    </ul>
  )
}

export default HeaderNav
