import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import Popup from 'mapbox-gl/src/ui/popup'
import MapboxLanguage from '@mapbox/mapbox-gl-language'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { ConvertedRecordItem } from '@/types/record'
import useRecordList from '@/hooks/useRecordList'
import { removeNumbers } from '@/utils/convert'
import usePeople from '@/hooks/usePeople'
import { useRecoilValue } from 'recoil'
import { recordsSearchKeywordState } from '@/pages/Records'

mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

let map
const zoomLevelChange = 13
const infoLatOffset = 0
let dongTextMakers: Popup[] = []

export interface MapProps {
  zoom: number
  centerCoors: number[]
  scroll: boolean
  move: boolean
}

const RecordsMap = ({ zoom, centerCoors, scroll, move }: MapProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [init, setInit] = useState<boolean>(false)
  const [markers, setMarkers] = useState<Popup[]>([])

  const recordsSearchKeyword = useRecoilValue(recordsSearchKeywordState)

  const { data: recordItems } = useRecordList({
    searchKeyword: recordsSearchKeyword,
  })
  const { data: intervieweeDetailsList } = usePeople(
    (recordItems ?? []).map((recordItem) => {
      return recordItem.interviewee
    })
  )

  useEffect(() => {
    if (!recordItems) return
    if (!intervieweeDetailsList) return

    if (map && !searchParams.get('uid')) {
      toggleMarkerStyle(map.getZoom())
    } else if (map) {
      const recordInfo: ConvertedRecordItem | undefined = recordItems.find(
        (a) => a.uid === searchParams.get('uid')
      )

      const intervieweeData = intervieweeDetailsList.find(
        (person) => person.name_search === recordInfo.interviewee
      )

      if (recordInfo) {
        map.flyTo({
          center: [
            Number(intervieweeData?.birth_place_lng),
            Number(intervieweeData?.birth_place_lat) - infoLatOffset,
          ],
          zoom: 14,
        })
      }
    }
  }, [map, searchParams.get('uid'), recordItems])

  /**
   * 지도 생성
   */
  const initMap = () => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

    map = new mapboxgl.Map({
      container: 'map', // container ID
      style: process.env.REACT_APP_MAPBOX_STYLE_RECORDS,
      center: centerCoors, // starting position [lng, lat]
      zoom: zoom, // starting zoom
      minZoom: zoom,
    })

    if (!scroll) {
      map.scrollZoom.disable()
    }

    if (!move) {
      map.dragPan.disable()
    }

    map.dragRotate.disable()
    // map.touchZoomRotate.disable()

    map.addControl(
      new MapboxLanguage({
        defaultLanguage: 'ko',
      })
    )

    map.on('load', () => {
      ;(async () => {
        const dongRes = await fetch('/data/daedeok.geojson')
        const dongSource = await dongRes.json()

        const dongPolygons = dongSource.features.map((feature) =>
          turf.multiPolygon(feature.geometry.coordinates)
        )
        const combinedPolygon = dongPolygons.reduce((accPolygon, polygon) => {
          return turf.union(accPolygon, polygon)
        })

        // 대덕구 아웃라인 마스킹
        map.addSource(`outline-masking`, {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: turf.mask(combinedPolygon).geometry.coordinates,
            },
          },
        })

        map.addLayer({
          id: 'outline-masking-fill',
          type: 'fill',
          source: 'outline-masking',
          layout: {},
          paint: {
            'fill-color': '#000000',
            'fill-opacity': 0.5,
          },
        })

        // 동별 이름 심볼 추가
        dongSource.features.forEach((feature) => {
          const center = turf.centroid(feature.geometry)
          const markerHtml = document.createElement('div')
          markerHtml.classList.add('map-dong-text')
          markerHtml.innerHTML = feature.properties.name

          const marker = new mapboxgl.Popup({
            className: `map-dong-text-popup`,
            closeButton: false,
            closeOnClick: false,
          })
            .setLngLat(center.geometry.coordinates)
            .setDOMContent(markerHtml)
            .addTo(map)

          let cords = center.geometry.coordinates

          marker.getElement().addEventListener('click', () => {
            setTimeout(() => {
              map.flyTo({
                center: cords,
                zoom: 13,
              })
            }, 100)
          })

          dongTextMakers.push(marker)
        })

        // 대덕구 내 동 구분 레이어
        map.addSource('daedeok-area', {
          type: 'geojson',
          data: dongSource,
        })

        map.addLayer({
          id: 'daedeok-area-line',
          type: 'line',
          source: 'daedeok-area',
          layout: {},
          paint: {
            'line-color': '#3b6161',
            'line-width': 2,
          },
        })

        // 클릭을 위한 동 구분 area-fill
        map.addLayer({
          id: 'daedeok-area-fill',
          type: 'fill',
          source: 'daedeok-area',
          paint: {
            'fill-color': 'transparent',
          },
        })

        map.on('click', 'daedeok-area-fill', (e) => {
          const pointer = new mapboxgl.Point(e.point.x, e.point.y)

          const features = map.queryRenderedFeatures(pointer, {
            layers: [`daedeok-area-fill`],
          })

          const clickedArea = features[0]

          const clickedAreaPolygon = turf.multiPolygon([
            clickedArea.geometry.coordinates,
          ])
          const center = turf.centroid(clickedAreaPolygon)

          // 클릭된 위치로 이동 및 줌
          // map.flyTo({
          //   center: center.geometry.coordinates,
          //   duration: 3200,
          //   essential: true,
          //   // zoom: 10,
          // })
        })

        const layers = map.getStyle().layers

        let firstSymbolId

        for (const layer of layers) {
          if (layer === 'symbol') {
            firstSymbolId = layer.id
            break
          }
        }

        setInit(true)

        addRecordMarkers()
      })()
    })

    map.on('moveend', () => {
      toggleMarkerStyle(map.getZoom())
    })
  }

  /**
   * 마커 추가
   */
  const addRecordMarkers = () => {
    try {
      removeMarkers()

      const addedMarkers: Popup[] = []

      recordItems.forEach(({ uid, interviewee, feature_image }) => {
        const name = removeNumbers(interviewee)

        const featureImage = feature_image
          ? `<div class='image' style='background-image: url(${
              feature_image.thumbnails && feature_image.thumbnails.length
                ? feature_image.thumbnails[feature_image.thumbnails.length - 1]
                    .path
                : feature_image.path
            })'></div>`
          : ''

        // 마커 엘리먼트 생성
        const markerHtml = document.createElement('div')

        markerHtml.classList.add('map-dot-content-container')
        markerHtml.classList.add('map-dot-content-info')
        markerHtml.classList.add('hidden-map')
        markerHtml.setAttribute('data-uid', uid)
        markerHtml.setAttribute('title', name)
        markerHtml.innerHTML = `
        ${featureImage}    
        <div class='flex justify-center'>
          <div class='name yellow'>
            ${name}
          </div>
        </div>
      `

        markerHtml.addEventListener('click', function () {
          navigate(`/records?uid=${uid}`)
        })
        markerHtml.addEventListener('mouseenter', function () {
          resetOtherMarkers(map.getZoom(), uid)
        })
        markerHtml.addEventListener('mouseleave', function () {
          toggleMarkerStyle(map.getZoom())
        })

        // 마커 그리기

        const intervieweeData = intervieweeDetailsList.find(
          (intervieweeDetails) => intervieweeDetails.name_search === interviewee
        )

        if (!intervieweeData) {
          console.log(`${interviewee}의 상세정보를 찾을 수 없습니다`)

          return
        }

        if (
          intervieweeData &&
          intervieweeData.birth_place_lat &&
          intervieweeData.birth_place_lng &&
          intervieweeData.birth_place_lat &&
          intervieweeData.birth_place_lng
        ) {
          const marker = new mapboxgl.Popup({
            className: `map-dot-popup cursor-pointer z-0`,
            closeButton: false,
            closeOnClick: false,
          })
            .setLngLat([
              Number(intervieweeData.birth_place_lng),
              Number(intervieweeData.birth_place_lat),
            ])
            .setDOMContent(markerHtml)
            .addTo(map)
          addedMarkers.push(marker)
        }
      })

      setMarkers(addedMarkers)
      toggleMarkerStyle(map.getZoom())
    } catch (error) {
      console.log('Error occurred drawing markers')
    }
  }

  /**
   * Dot 마커 지우기
   */
  const removeMarkers = () => {
    markers.map((marker) => {
      marker.remove()
    })

    setMarkers([])

    document.querySelectorAll('.map-dot-popup').forEach((popup) => {
      popup.remove()
    })

    // removeInfoMaker()
  }

  /**
   * Zoom 별 마커 스타일 변경
   */
  const toggleMarkerStyle = (zoomLevel) => {
    const popups = document.querySelectorAll('.map-dot-popup')
    const currentArchiveUid = window.location.search
      ? window.location.search.split('?uid=')[1]
      : null

    popups.forEach((popup) => {
      const popupContainer = popup.querySelector('.map-dot-content-container ')
      const popupContainerUid = popupContainer?.getAttribute('data-uid')

      if (currentArchiveUid && currentArchiveUid === popupContainerUid) {
        popup.classList.add('map-no-dot')
        popupContainer?.classList.remove('map-no-hover')
        popupContainer?.classList.remove('hidden-map')
      } else {
        if (!currentArchiveUid && zoomLevel > zoomLevelChange) {
          popup.classList.add('map-no-dot')
          popupContainer?.classList.remove('map-no-hover')
          popupContainer?.classList.remove('hidden-map')
        } else {
          popup.classList.remove('map-no-dot')
          popupContainer?.classList.add('hidden-map')

          if (currentArchiveUid) {
            popupContainer?.classList.add('map-no-hover')
          }
        }
      }
    })
  }

  /**
   * 다른 마커 최소화
   * @param zoomLevel
   * @param uid
   */
  const resetOtherMarkers = (zoomLevel, uid) => {
    const popups = document.querySelectorAll('.map-dot-popup')
    const currentArchiveUid = window.location.search
      ? window.location.search.split('?uid=')[1]
      : null

    if (currentArchiveUid) return

    popups.forEach((popup) => {
      const popupContainer = popup.querySelector('.map-dot-content-container ')
      const popupContainerUid = popupContainer?.getAttribute('data-uid')

      if (zoomLevel > zoomLevelChange && uid !== popupContainerUid) {
        popup.classList.remove('map-no-dot')
        popupContainer?.classList.add('hidden-map')
      }
    })
  }

  useEffect(() => {
    if (!recordItems || !intervieweeDetailsList) return

    initMap()
  }, [recordItems, intervieweeDetailsList])

  if (!recordItems || !intervieweeDetailsList) return <></>

  return (
    <div
      id="map"
      className="flex-1 w-full h-[75vh] maxmd:h-[100svh] max-h-full"></div>
  )
}

export default RecordsMap
