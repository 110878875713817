import { selectedFiltersState } from '@/components/archives/ArchivesNav'
import useModelCategory from '@/hooks/useModelCategory'
import { findCategory } from '@/utils'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSetRecoilState } from 'recoil'

const keywordNames = [
  '사건',
  '문헌',
  '장소',
  '유물',
  '회덕동',
  '신탄진동',
  '석봉동',
  '1980년대',
  '2000년대',
  '2010년대',
]

const groupCount = 3
let groupLoop = 0
let groupInterval: any = null

const MainKeywords = () => {
  // Effect
  useEffect(() => {
    playNextKeyword()

    if (groupInterval) {
      clearInterval(groupInterval)
    }

    groupInterval = setInterval(() => {
      playNextKeyword()
    }, 3000)
  }, [])

  /**
   * 키워드 애니메이션 재생
   */
  const playNextKeyword = () => {
    const currentGroupIdx = groupLoop % groupCount
    const keywords = document.querySelectorAll('.home-keyword-item')

    keywords.forEach((keyword, kIdx) => {
      keyword.classList.remove('show-keyword')

      const currentKeywords = document.querySelectorAll(
        '.home-keyword-group-' + currentGroupIdx
      )
      currentKeywords.forEach((currentKeyword) => {
        currentKeyword.classList.add('show-keyword')
      })
    })

    groupLoop = groupLoop + 1
  }

  const navigate = useNavigate()
  const setSelectedFilter = useSetRecoilState(selectedFiltersState)

  const { data: categoryNames } = useModelCategory('archives')

  if (!categoryNames) return <></>

  const keywords = keywordNames.map((keyword) =>
    findCategory(categoryNames, keyword)
  )

  return (
    <div>
      {keywords.map((keyword, hIdx) => (
        <div key={`main-keyword-${hIdx}-${keyword?.categoryId ?? ''}`}>
          {keyword && (
            <div
              className={`home-keyword-item home-keyword-group-${
                hIdx % groupCount
              } home-keyword-item-${hIdx + 1}`}
              onClick={() => {
                setSelectedFilter((prev) => [...prev, keyword])
                navigate(`/archives/list`)
              }}>
              <div className="dot"></div>
              <div className="name">{keyword.name}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default MainKeywords
