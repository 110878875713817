import clsx from 'clsx'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import LocationIcon from '@/assets/images/record-item-locate.svg'
import { ConvertedRecordItem } from '@/types/record'

type Props = {
  recordItem: ConvertedRecordItem
}

const RecordsItem = ({ recordItem }: Props) => {
  const [hovered, setHovered] = useState(false)

  const navigate = useNavigate()

  const { uid, feature_image, feature_text, interviewee, location_address } =
    recordItem

  if (!feature_image?.path) return <></>

  return (
    <div
      className={clsx(
        'w-[310px] h-[260px]',
        'relative',
        'border-primary border-[1px]',
        'color-primary',
        'cursor-pointer',
        'maxmd:w-full maxmd:h-fit',
        'maxmd:pb-[40px]',
        '[&:last-child]:mr-auto'
      )}
      onClick={() => navigate(`/records?uid=${uid}`)}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <div className="flex flex-col">
        <img className="md:h-[220px] maxmd:w-full" src={feature_image.path} />

        <div
          className={clsx(
            'bg-white w-full h-full',
            'font-chulae text-black text-[20px] leading-[30px]',
            'px-[8px] pt-[8px] pb-[12px]',
            'md:absolute md:px-[24px] md:py-[20px]',
            hovered ? '!md:block' : 'md:hidden'
          )}>
          {feature_text}
        </div>
      </div>

      <div
        className={clsx(
          'absolute bottom-0 left-0',
          'w-full bg-white',
          'flex items-center justify-between records-item-body',
          'px-[20px] py-[9px]',
          'h-[40px]',
          'font-semibold leading-4'
        )}>
        <div>{interviewee}</div>
        <div className="flex">
          <img className="mr-[4px]" src={LocationIcon} />
          {location_address}
        </div>
      </div>
    </div>
  )
}

export default RecordsItem
