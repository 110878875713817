import clsx from 'clsx'
import { last } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import Loading from '@/components/Loading'
import useArchives from '@/hooks/useArchives'
import useImages from '@/hooks/useImages'
import { convertToFormattedDate } from '@/utils'
import { convertOrigin, pickImageSource } from '@/utils/convert'
import { useSearchParams } from 'react-router-dom'

const ArchivesInfo = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [item, setItem] = useState(null)

  const { data: archives } = useArchives({})

  useEffect(() => {
    const uid = searchParams.get('uid')

    const item = archives.find((archive) => archive.uid === uid)
    setItem(item)
  }, [archives, searchParams.get('uid')])

  /**
   * 아카이브 정보 닫기
   */
  const closeInfo = () => {
    navigate(location.pathname)
  }

  return (
    <ArchivesInfo.Container show={!!item}>
      {/* 헤더 */}
      <ArchivesInfo.Header>
        <div className="flex items-center truncate space-x-2.5 md:space-x-4">
          <ul className="flex items-center space-x-1">
            {Boolean(item?.category_main) && (
              <li className="label">{item?.category_main}</li>
            )}
            {Boolean(item?.category_sub) && Boolean(item?.category_sub) && (
              <>
                <li className="label-tip"></li>
                <li className="label">{item?.category_sub}</li>
              </>
            )}
          </ul>

          <h2 className="truncate color-primary">{item?.title}</h2>
        </div>

        <button
          type="button"
          className="btn btn-close"
          onClick={() => closeInfo()}></button>
      </ArchivesInfo.Header>

      <ArchivesInfo.Contents></ArchivesInfo.Contents>
    </ArchivesInfo.Container>
  )
}

ArchivesInfo.Container = ({
  children,
  show,
}: {
  children: ReactNode
  show: boolean
}) => {
  return (
    <div
      className={clsx(
        'fixed z-40',
        'w-full h-[calc(100svh-70px)] maxmd:h-[100svh] bg-white',
        'duration-700',
        show ? 'top-[70px] maxmd:top-[0px] maxmd:border-t-[0px]' : 'top-[100%]',
        'flex flex-col',
        'border-t-[1px] border-primary'
      )}>
      {children}
    </div>
  )
}

ArchivesInfo.Header = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex items-center justify-between p-4 space-x-1 leading-5 archive-info-header md:p-5 border-b-[1px] border-primary">
      {children}
    </div>
  )
}

ArchivesInfo.Contents = () => {
  const location = useLocation()

  const [archiveUid, setArchiveUid] = useState(null)

  useEffect(() => {
    const uid = last(location.search.split('uid='))

    setArchiveUid(uid)
  }, [location])

  const { data: archives } = useArchives({})
  const { data: images } = useImages(
    (archives ?? []).flatMap((item) => item.images)
  )

  if (!archives || !archiveUid || !images) return <Loading hasLayout={false} />

  const archiveItem = archives.find((archive) => archive.uid === archiveUid)

  const mappedImages = archiveItem.images.map((imageName) => {
    return images.find((image) => image.search_name === imageName)
  })

  return (
    <div className="flex flex-1 maxmd:flex-col maxmd:h-[calc(100svh-52px)] maxmd:overflow-y-scroll break-all">
      <div className="flex-[2] maxmd:flex-none maxmd:h-fit min-w-[240px] border-r-[1px] maxmd:border-r-[0px] border-primary h-[calc(100svh-131px)] overflow-scroll hide-scrollbar">
        <ul className="p-[20px]">
          <ArchivesInfo.Summary label="주소" value={archiveItem.address} />
          <ArchivesInfo.Summary label="설립연도" value={archiveItem.date} />
          <ArchivesInfo.Summary label="작성자" value={archiveItem.author} />
          <ArchivesInfo.Summary
            label="조사기간"
            value={`${convertToFormattedDate(
              archiveItem.start
            )} - ${convertToFormattedDate(archiveItem.end)}`}
          />
          <ArchivesInfo.Summary
            label="작성일"
            value={convertToFormattedDate(archiveItem.created_date)}
          />
        </ul>
      </div>

      <div
        className={clsx(
          'flex-[6] border-r-[1px] border-primary h-[calc(100svh-131px)] overflow-scroll hide-scrollbar',
          'maxmd:flex-none maxmd:h-fit maxmd:border-t-[1px] maxmd:border-r-[0px] maxmd:!border-primary'
        )}>
        <p className="text-[18px] leading-[30.6px] p-[20px] break-keep">
          {archiveItem.desc}
        </p>

        <ul>
          <Origins origins={archiveItem.origin} />
        </ul>
      </div>

      <div
        className={clsx(
          'flex-[6] h-[calc(100svh-131px)] overflow-scroll hide-scrollbar',
          'maxmd:flex-none maxmd:h-fit maxmd:border-t-[1px] maxmd:!border-primary'
        )}>
        <div className="p-[20px]">
          {mappedImages &&
            mappedImages.length >= 1 &&
            mappedImages.map((image) => (
              <div key={image.uid} className="mb-[30px]">
                <img className="block mx-auto" src={pickImageSource(image)} />
                <div className="leading-[19.6px] text-[14px] color-[#3B6161] text-center mt-[10px]">
                  {image.name}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

ArchivesInfo.Summary = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className="flex flex-col mb-[12px]">
      <label className="text-[14px] leading-[19.6px] font-bold color-primary mb-[4px]">
        {label}
      </label>

      <span className="text-[14px] leading-[19.6px]">{value}</span>
    </div>
  )
}

const Origins = ({ origins }: { origins: string }) => {
  const converted = convertOrigin(origins)

  return (
    <ul className="list-disc pl-[2.5em] p-[20px] border-t-[1px]">
      {converted.map((originStr, oIdx) => (
        <li className="mb-[10px]" key={oIdx}>
          {originStr.replace('"', '').replace('"', ': ')}
        </li>
      ))}
    </ul>
  )
}

export default ArchivesInfo
