import { pipe } from '@fxts/core'
import { useQuery } from 'react-query'

import { ConvertedProject, Project } from '@/types/project'
import { MEMEX_URLS, createMemexFetcher } from '@/utils/memexFetcher'

const useProjects = () => {
  return useQuery({
    queryKey: 'projects',
    queryFn: async () => {
      const memexFetcher = createMemexFetcher()

      return await memexFetcher.post(MEMEX_URLS.GET_LISTS.PROJECTS, {
        size: 100,
        page: 0,
      })
    },
    select(data) {
      const converted = pipe(
        data,
        (data) => data.data.list,
        (list) =>
          list.map(({ uid, data }) => ({
            uid,
            ...data,
          })),
        (list) =>
          list.map((item) => {
            return convertProjectItem(item)
          })
      )

      return converted as Array<ConvertedProject>
    },
    refetchOnWindowFocus: false,
  })
}

const convertProjectItem = (item: Project) => {
  return Object.entries(item).reduce((acc, [key, value]) => {
    acc[key] = value

    if (value['KO'] !== undefined) {
      acc[key] = value['KO']
    }

    if (key === 'image') {
      acc[key] = value.map((item) => ({
        path: item.file.path,
        thumbnailList: item.file.thumbnailList.map((item) => item.path),
      }))
    }

    return acc
  }, {})
}

export default useProjects
