import { pipe } from '@fxts/core'

import { Filter } from '@/components/archives/ArchivesNav'
import { FilterKey } from '@/hooks/useArchives'
import { DonationInput } from '@/pages/Donates'

export const 대전광역시_제거하기 = (address: string) => {
  return address
    .replace('대전광역시', '')
    .replace('충청북도', '')
    .replace('충청남도', '')
    .trim()
}

// 스트링 타입 값들이 모두 들어있는지 확인
export const 기증서_필수값_점검 = (donationInput: DonationInput) => {
  const requiredFields = [
    'name',
    'email',
    'phone',
    'address',
    'archiveName',
    'archiveType',
    'agreement',
  ]

  const invalidField = pipe(
    donationInput,
    Object.entries,
    (entries) =>
      entries.find(([label, value]) => {
        // 필수값들만 확인
        if (!requiredFields.includes(label)) return false

        // 특별한 확인사항들
        if (label === 'email') {
          const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)

          return !isValidEmail
        }

        if (label === 'archiveType') {
          const isValidCategory =
            typeof value === 'number' && String(value).length >= 1

          return !isValidCategory
        }

        if (label === 'agreement') {
          const hasAgreed = !(value === true)

          return hasAgreed
        }

        // 값 존재 유무
        return !value.length
      }),
    (findResult) => (findResult ? findResult[0] : null)
  )

  return {
    isValid: !invalidField,
    invalidField,
  }
}

export const assembleArchiveFilter = (selectedFilters) => {
  if (selectedFilters.length <= 0) {
    return null
  }

  return selectedFilters.reduce((acc, { categoryKey, name }) => {
    if (!acc[categoryKey]) {
      acc[categoryKey] = []
    }

    acc[categoryKey].push(name)

    return acc
  }, {} as Record<FilterKey, string[]>)
}

export const splitArray = (arr: Array<unknown>, splitter: number) => {
  const result = []

  for (let i = 0; i < arr.length; i += splitter) {
    result.push(arr.slice(i, i + splitter))
  }

  return result
}

export const findCategory = (
  categories: Array<Filter>,
  categoryName: string
) => {
  return Object.values(categories)
    .flatMap((category) => category)
    .find((category) => category.name === categoryName)
}

export const convertFormattedSecondAsNumber = (duration: string) => {
  // Split the duration string into parts
  const parts = duration.split(':').map(Number)

  // Calculate the total seconds
  const seconds = parts.reduce((acc, val, index) => {
    if (index === 0) {
      // Hours
      return acc + val * 60 * 60
    } else if (index === 1) {
      // Minutes
      return acc + val * 60
    } else {
      // Seconds
      return acc + val
    }
  }, 0)

  return seconds
}

export const convertToFormattedDate = (date: string) => {
  return date.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')
}
